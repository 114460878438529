<template>
  <div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Seguro que quieres eliminarlo?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteEPGDrug"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-btn
          @click="$router.go(-1)"
          class="mx-2"
          fab
          dark
          small
          color="primary"
        >
          <v-icon dark>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        {{ epgData.epg.name }}
        <v-spacer></v-spacer>
        <v-btn @click="saveEPGDrug" class="mx-2" fab dark small color="green">
          <v-icon dark>
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          @click="dialogDelete = true"
          class="mx-2"
          fab
          dark
          small
          color="red"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="activeSection"
          background-color="deep-purple accent-4"
          center-active
          dark
        >
          <v-tab
            v-for="section in epgDrugSections"
            :key="section"
            @change="tabChanged"
          >
            {{ section.replaceAll("_", " ") }}
          </v-tab>
        </v-tabs>
        <MarkdownField v-model="epgData.epg[epgDrugSections[activeSection]]" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import MarkdownField from "@/components/mylogy/MarkdownField.vue";
export default {
  props: ["drugId"],
  components: {
    MarkdownField
  },
  data() {
    return {
      dialogDelete: false,
      activeSection: 0,
      epgDrugSections: [
        "name",
        "brand_names",
        "drug_combinations",
        "chemistry",
        "pharmacologic_category",
        "mechanism_of_action",
        "therapeutic_use",
        "pregnancy_and_lactation_implications",
        "unlabeled_use",
        "contraindications",
        "warnings_and_precautions",
        "adverse_reactions",
        "toxicological_effects",
        "caution",
        "other_genes_that_may_be_involved",
        "substrate_of",
        "induces",
        "inhibits",
        "drug_interactions",
        "dosage",
        "pharmacokinetics_and_pharmacodynamics",
        "special_considerations",
        "cns_effects",
        "genes_that_may_be_involved",
        "nutrition_nutraceutical_interactions"
      ],
      epgData: {
        hide: false,
        epg: {
          wp_id: null,
          has_atc: null,
          has_cas: null,
          _id: null,
          name: "",
          sku: null,
          brand_names: null,
          drug_combinations: null,
          chemistry: null,
          pharmacologic_category: null,
          mechanism_of_action: null,
          therapeutic_use: null,
          unlabeled_use: null,
          contraindications: null,
          warnings_and_precautions: null,
          adverse_reactions: null,
          caution: null,
          other_genes_that_may_be_involved: null,
          substrate_of: null,
          inhibits: null,
          induces: null,
          drug_interactions: null,
          nutrition_nutraceutical_interactions: null,
          dosage: null,
          pharmacokinetics_and_pharmacodynamics: null,
          special_considerations: null,
          toc: null,
          max_atc_lvl: null,
          atc_codes: null,
          cas_codes: null,
          pharm_gkb_id: null,
          drug_central: null,
          kb_drug: null,
          id: null
        }
      }
    };
  },
  computed: {},
  mounted() {
    let self = this;
    Strapi.get(`/drugs/${this.drugId}/kb`).then(res => {
      if (res.data.epg) {
        self.epgData = res.data;
      }
      self.epgData.epg.kb_drug = self.drugId;
    });
  },
  methods: {
    saveEPGDrug() {
      let self = this;
      if (self.epgData.epg.id) {
        self.updateEPGDrug();
      } else {
        self.createEPGDrug();
      }
    },
    createEPGDrug() {
      let self = this;
      Strapi.post(`/drugs`, this.epgData.epg).then(res => {
        self.epgData.epg = res.data;
        self.$alertify.success("Creado correctamente");
      });
    },
    updateEPGDrug() {
      let self = this;
      Strapi.put(`/drugs/${this.epgData.epg.id}`, this.epgData.epg).then(
        res => {
          self.epgData.epg = res.data;
          self.$alertify.success("Actualizado correctamente");
        }
      );
    },
    deleteEPGDrug() {
      let self = this;
      Strapi.delete(`/drugs/${this.epgData.epg.id}`).then(() => {
        self.$alertify.success("Eliminado correctamente");
        self.$router.go(-1);
      });
    },
    tabChanged(tab) {
      console.log(tab);
    }
  }
};
</script>
<style scoped></style>
